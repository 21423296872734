import React, { ReactElement, useState } from "react"
import { Link } from "gatsby"

const navigationItems = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Portfolio",
    href: "/portfolio",
  },
  {
    name: "Imprint",
    href: "/imprint",
  },
]
export const Layout: React.FunctionComponent = ({ children }): ReactElement => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className="relative">
        <nav className="flex flex-wrap items-center justify-between p-6 bg-denim-500">
          <div className="flex items-center flex-shrink-0 mr-6 text-white">
            <span className="text-xl font-semibold tracking-tight">
              Hans-Christian Otto
            </span>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={() => {
                setMenuOpen((isMenuOpen) => !isMenuOpen)
              }}
              className="flex items-center px-3 py-2 text-teal-200 border border-teal-400 rounded hover:text-white hover:border-white"
            >
              <svg
                className="w-3 h-3 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div
            className={`w-full ${
              isMenuOpen ? "block" : "hidden"
            } lg:block flex-grow lg:flex lg:items-center lg:w-auto`}
          >
            <div className="text-sm lg:flex-grow">
              {navigationItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="block mt-4 mr-4 text-teal-200 lg:inline-block lg:mt-0 hover:text-white"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
      <div className="m-2">{children}</div>
      <a style={{display: "none"}} rel="me" href="https://ruhr.social/@muhdiekuh">Mastodon</a>
    </>
  )
}
